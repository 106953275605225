* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}
body {
  padding: 0px;
  overflow: hidden scroll;
  width: 100%;
}
/* body.dialog-open {
  overflow: hidden !important;
} */

.app {
  min-height: 100vh;
  width: 100%;
  font-family: 'Segoe UI', Roboto, Tahoma, Geneva, Verdana, sans-serif;
}
.custom-errors a {
  color: #fff !important;
  text-decoration: none !important;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: '40px !important';
}

#root ~ iframe {
  pointer-events: none;
}

.input-chat .MuiInputBase-root {
  padding: 0px 6px 6px 8px;
}

.boxScrollStyle::-webkit-scrollbar {
  width: 8px;
}
.boxScrollStyle::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.boxScrollStyle::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 50px;
}

.max-2-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-4-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.opt-container {
  display: flex !important;
  justify-content: space-between !important;
  gap: 20px !important;
}

.opt-container input {
  border-radius: 5px !important;
  border: 2px solid rgb(186, 185, 185) !important;
  height: 45px !important;
  margin: 0 !important;
  width: 45px !important;
}

.opt-container input:focus {
  outline-color: rgb(249, 151, 3) !important;
}

.loader {
  --r1: 154%;
  --r2: 68.5%;
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(
      var(--r1) var(--r2) at top,
      #0000 79.5%,
      #7165e0 80%
    ),
    radial-gradient(var(--r1) var(--r2) at bottom, #7165e0 79.5%, #0000 80%),
    radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #7165e0 80%), #ccc;
  background-size: 50.5% 220%;
  background-position: -100% 0%, 0% 0%, 100% 0%;
  background-repeat: no-repeat;
  animation: l9 2s infinite linear;
}
@keyframes l9 {
  33% {
    background-position: 0% 33%, 100% 33%, 200% 33%;
  }
  66% {
    background-position: -100% 66%, 0% 66%, 100% 66%;
  }
  100% {
    background-position: 0% 100%, 100% 100%, 200% 100%;
  }
}
#landingPageDialog .MuiPaper-root {
  box-shadow: none;
  background-color: transparent;
  background-image: none;
}

.no-scroll {
  overflow: hidden !important;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}