.EmojiPickerReact.epr-main {
  flex-direction: column-reverse !important;
}

.EmojiPickerReact .epr-body {
  overflow-y: scroll;
}

.EmojiPickerReact .epr-body::-webkit-scrollbar {
  width: 10px;
  background-color: #f1f1f1;
}
.EmojiPickerReact .epr-body::-webkit-scrollbar-track{
  background: #f1f1f1
}
.EmojiPickerReact .epr-body::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px
}
.EmojiPickerReact .epr-body::-webkit-scrollbar-thumb:hover {
  background: rgb(200, 200, 200);
}

.EmojiPickerReact .epr-header .epr-category-nav {
  padding:10px
}
