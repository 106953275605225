.typing__dot {
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

.message__status {
  animation: fadeStatusMessage 0.3s ease-in-out;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
    transform: scale(0.25);
  }
  50% {
    opacity: 0.7;
    transform: scale(0.7);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes fadeStatusMessage {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.borderRepliedMessageDark {
  border: 1px solid #fafafa;
}
.borderRepliedMessageLight {
  border: 1px solid #090909;
}
