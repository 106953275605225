.popover-content {
  margin: 10;
}
.emoji {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  padding: 5;
  cursor: pointer;
  /* transform: scale(0.985); */
  transition: all 0.2s ;
}
.emoji:hover {
  transform: matrix(1.3, 0, 0, 1.3, 0, -4);
  /* transform: translateY(-15%) scale(1.2); */
}
.emoji:after {
  position: absolute;
  bottom: -16px;
  font-size: 7.2px;
  width: 24px;
  left: calc(50% - 12px);
  color: #8a8a8a;
}

.emoji__face,
.emoji__eyebrows,
.emoji__eyes,
.emoji__mouth,
.emoji__tongue,
.emoji__heart,
.emoji__hand,
.emoji__thumb {
  position: absolute;
}
.emoji__face:before,
.emoji__face:after,
.emoji__eyebrows:before,
.emoji__eyebrows:after,
.emoji__eyes:before,
.emoji__eyes:after,
.emoji__mouth:before,
.emoji__mouth:after,
.emoji__tongue:before,
.emoji__tongue:after,
.emoji__heart:before,
.emoji__heart:after,
.emoji__hand:before,
.emoji__hand:after,
.emoji__thumb:before,
.emoji__thumb:after {
  position: absolute;
  content: '';
}

.emoji__face {
  width: inherit;
  height: inherit;
}

.emoji--like {
  background: #548dff;
}
.emoji .reactionLabel {
  visibility: hidden;
  background-color: black;
  width: 60px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  border-radius: 15px;
  padding: 3px;
  margin-left: -30px;
  opacity: 0.8;

  /* Position the tooltip */
  position: absolute;
  bottom: 110%;
  margin-bottom: 18px;
  left: 50%;
  z-index: 1;
}
.emoji--yay .reactionLabel {

  width: 98px;
  margin-left: -49px;
  opacity: 0.8;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.emoji:hover .reactionLabel {
  visibility: visible;
}

.emoji--like .emoji__hand {
  left: 10px;
  bottom: 12px;
  width: 8px;
  height: 16px;
  background: #ffffff;
  border-radius: 2px;
  z-index: 0;
  -webkit-animation: hands-up 2s linear infinite;
  animation: hands-up 2s linear infinite;
}
.emoji--like .emoji__hand:before {
  left: 10px;
  bottom: 2px;
  width: 16px;
  background: inherit;
  height: 4px;
  border-radius: 0.8px 4px 4px 0.8px;
  box-shadow: 0.4px -3.6px 0 0.4px #ffffff, 0.8px -7.6px 0 0.8px #ffffff,
    1.2px -11.6px 0 1.2px #ffffff;
}
.emoji--like .emoji__thumb {
  border-bottom: 8px solid #ffffff;
  border-left: 8px solid transparent;
  top: -10px;
  right: -10px;
  z-index: 2;
  -webkit-transform: rotate(5deg);
  transform: rotate(5deg);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-animation: thumbs-up 2s linear infinite;
  animation: thumbs-up 2s linear infinite;
}
.emoji--like .emoji__thumb:before {
  border-radius: 50% 50% 0 0;
  background: #ffffff;
  width: 4px;
  height: 4.8px;
  left: -4px;
  top: -3.2px;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  box-shadow: -0.4px 1.6px 0 -0.4px #ffffff;
}

.emoji--love {
  background: #f55064;
}
.emoji--love .emoji__heart {
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  -webkit-animation: heart-beat 1s linear infinite alternate;
  animation: heart-beat 1s linear infinite alternate;
}
.emoji--love .emoji__heart:before,
.emoji--love .emoji__heart:after {
  left: calc(50% - 8px);
  top: calc(50% - 12.8px);
  width: 16px;
  height: 25.4px;
  background: #ffffff;
  border-radius: 8px 8px 0 0;
}
.emoji--love .emoji__heart:before {
  -webkit-transform: translate(8px) rotate(-45deg);
  transform: translate(8px) rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.emoji--love .emoji__heart:after {
  -webkit-transform: translate(-8px) rotate(45deg);
  transform: translate(-8px) rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.emoji--haha .emoji__face {
  -webkit-animation: haha-face 2s linear infinite;
  animation: haha-face 2s linear infinite;
}
.emoji--haha .emoji__eyes {
  width: 10.4px;
  height: 2.4px;
  border-radius: 0.8px;
  left: calc(50% - 5.2px);
  top: 14px;
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
  background: transparent;
  box-shadow: -10px 2px 0 0 #000000, 10px -2px 0 0 #000000;
}
.emoji--haha .emoji__eyes:after {
  left: 0;
  top: 0;
  width: 10.4px;
  height: 2.4px;
  border-radius: 0.8px;
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  background: transparent;
  box-shadow: -10px -2px 0 0 #000000, 10px 2px 0 0 #000000;
}
.emoji--haha .emoji__mouth {
  width: 32px;
  height: 16px;
  left: calc(50% - 16px);
  top: 50%;
  background: #000000;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  z-index: 1;
  -webkit-animation: haha-mouth 2s linear infinite;
  animation: haha-mouth 2s linear infinite;
}
.emoji--haha .emoji__tongue {
  width: 28px;
  height: 12px;
  background: #f55064;
  left: calc(50% - 14px);
  bottom: -4px;
  border-radius: 50%;
}

.emoji--yay:after {
  -webkit-animation: yay-reverse 1s linear infinite;
  animation: yay-reverse 1s linear infinite;
}
.emoji--yay .emoji__face {
  -webkit-animation: yay 1s linear infinite alternate;
  animation: yay 1s linear infinite alternate;
}
.emoji--yay .emoji__eyebrows {
  left: calc(50% - 1.2px);
  top: 12px;
  height: 2.4px;
  width: 2.4px;
  border-radius: 50%;
  background: transparent;
  box-shadow: -2.4px 0 0 0 #000000, -14.4px 0 0 0px #000000, 2.4px 0 0 0 #000000,
    14.4px 0 0 0px #000000;
}
.emoji--yay .emoji__eyebrows:before,
.emoji--yay .emoji__eyebrows:after {
  width: 14.4px;
  height: 7.2px;
  border-radius: 24px 24px 0 0;
  background: transparent;
  border: 2.4px solid black;
  box-sizing: border-box;
  border-bottom: 0;
  bottom: 1.2px;
  left: calc(50% - 7.2px);
}
.emoji--yay .emoji__eyebrows:before {
  margin-left: -8.4px;
}
.emoji--yay .emoji__eyebrows:after {
  margin-left: 8.4px;
}
.emoji--yay .emoji__mouth {
  top: 24px;
  background: transparent;
  left: 50%;
}
.emoji--yay .emoji__mouth:after {
  width: 32px;
  height: 32px;
  left: calc(50% - 16px);
  top: -30px;
  border-radius: 50%;
  background: transparent;
  border: 2.4px solid #000000;
  box-sizing: border-box;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 1;
}
.emoji--yay .emoji__mouth:before {
  width: 2.4px;
  height: 2.4px;
  background: transparent;
  border-radius: 50%;
  bottom: 2px;
  left: calc(50% - 1.2px);
  box-shadow: -10px 0 0 0 #000000, 10px 0 0 0 #000000,
    -14px -0.8px 12px 4px #d5234c, 14px -0.8px 12px 4px #d5234c;
}

.emoji--wow .emoji__face {
  -webkit-animation: wow-face 3s linear infinite;
  animation: wow-face 3s linear infinite;
}
.emoji--wow .emoji__eyebrows {
  left: calc(50% - 1.2px);
  height: 2.4px;
  width: 2.4px;
  border-radius: 50%;
  background: transparent;
  box-shadow: -7.2px 0 0 0 #000000, -13.2px 0 0 0 #000000, 7.2px 0 0 0 #000000,
    13.2px 0 0 0 #000000;
  -webkit-animation: wow-brow 3s linear infinite;
  animation: wow-brow 3s linear infinite;
}
.emoji--wow .emoji__eyebrows:before,
.emoji--wow .emoji__eyebrows:after {
  width: 9.6px;
  height: 8px;
  border: 2.4px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: -1.2px;
  left: calc(50% - 4.8px);
}
.emoji--wow .emoji__eyebrows:before {
  margin-left: -10px;
}
.emoji--wow .emoji__eyebrows:after {
  margin-left: 10px;
}
.emoji--wow .emoji__eyes {
  width: 6.4px;
  height: 9.6px;
  left: calc(50% - 3.2px);
  top: 14px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 10px 0 0 0 #000000, -10px 0 0 0 #000000;
}
.emoji--wow .emoji__mouth {
  width: 12px;
  height: 18px;
  left: calc(50% - 6px);
  top: 50%;
  border-radius: 50%;
  background: #000000;
  -webkit-animation: wow-mouth 3s linear infinite;
  animation: wow-mouth 3s linear infinite;
}

.emoji--sad .emoji__face {
  -webkit-animation: sad-face 2s ease-in infinite;
  animation: sad-face 2s ease-in infinite;
}
.emoji--sad .emoji__eyebrows {
  left: calc(50% - 1.2px);
  top: 14px;
  height: 2.4px;
  width: 2.4px;
  border-radius: 50%;
  background: transparent;
  box-shadow: -16px 3.6px 0 0 #000000, -10px 0 0 0 #000000, 10px 0 0 0 #000000,
    16px 3.6px 0 0 #000000;
}
.emoji--sad .emoji__eyebrows:before,
.emoji--sad .emoji__eyebrows:after {
  width: 12px;
  height: 8px;
  border: 2.4px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 0.8px;
  left: calc(50% - 6px);
}
.emoji--sad .emoji__eyebrows:before {
  margin-left: -12px;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}
.emoji--sad .emoji__eyebrows:after {
  margin-left: 12px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.emoji--sad .emoji__eyes {
  width: 5.6px;
  height: 6.4px;
  left: calc(50% - 2.8px);
  top: 20px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 10px 0 0 0 #000000, -10px 0 0 0 #000000;
}
.emoji--sad .emoji__eyes:after {
  background: #548dff;
  width: 4.8px;
  height: 4.8px;
  margin-left: 2.4px;
  border-radius: 0 100% 40% 50% / 0 50% 40% 100%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-animation: tear-drop 2s ease-in infinite;
  animation: tear-drop 2s ease-in infinite;
}
.emoji--sad .emoji__mouth {
  width: 24px;
  height: 32px;
  left: calc(50% - 12px);
  top: 32px;
  box-sizing: border-box;
  border: 2.4px solid #000000;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  background: transparent;
  -webkit-animation: sad-mouth 2s ease-in infinite;
  animation: sad-mouth 2s ease-in infinite;
}
.emoji--sad .emoji__mouth:after {
  width: 2.4px;
  height: 2.4px;
  background: transparent;
  border-radius: 50%;
  top: 1.6px;
  left: calc(50% - 1.2px);
  box-shadow: -7.2px 0 0 0 #000000, 7.2px 0 0 0 #000000;
}

.emoji--angry {
  background: linear-gradient(#d5234c -10%, #ffda6a);
  background-size: 100%;
  -webkit-animation: angry-color 2s ease-in infinite;
  animation: angry-color 2s ease-in infinite;
}
.emoji--angry .emoji__face {
  -webkit-animation: angry-face 2s ease-in infinite;
  animation: angry-face 2s ease-in infinite;
}
.emoji--angry .emoji__eyebrows {
  left: calc(50% - 1.2px);
  top: 22px;
  height: 2.4px;
  width: 2.4px;
  border-radius: 50%;
  background: transparent;
  box-shadow: -17.6px 2px 0 0 #000000, -2.8px 6.4px 0 0 #000000,
    2.8px 6.4px 0 0 #000000, 17.6px 2px 0 0 #000000;
}
.emoji--angry .emoji__eyebrows:before,
.emoji--angry .emoji__eyebrows:after {
  width: 20px;
  height: 8px;
  border: 2.4px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 0;
  left: calc(50% - 10px);
}
.emoji--angry .emoji__eyebrows:before {
  margin-left: -10px;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}
.emoji--angry .emoji__eyebrows:after {
  margin-left: 10px;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
.emoji--angry .emoji__eyes {
  width: 4.8px;
  height: 4.8px;
  left: calc(50% - 2.4px);
  top: 28px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 10px 0 0 0 #000000, -10px 0 0 0 #000000;
}
.emoji--angry .emoji__mouth {
  width: 14.4px;
  height: 7.2px;
  left: calc(50% - 7.2px);
  bottom: 6px;
  background: #000000;
  border-radius: 50%;
  -webkit-animation: angry-mouth 2s ease-in infinite;
  animation: angry-mouth 2s ease-in infinite;
}

@-webkit-keyframes heart-beat {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@keyframes heart-beat {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}
@-webkit-keyframes haha-face {
  10%,
  30%,
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  20%,
  40% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  60%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  70%,
  90% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
@keyframes haha-face {
  10%,
  30%,
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  20%,
  40% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  60%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  70%,
  90% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
@-webkit-keyframes haha-mouth {
  10%,
  30%,
  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    top: 45%;
  }
  20%,
  40% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    top: 45%;
  }
  60%,
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    top: 50%;
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    top: 50%;
  }
  90% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    top: 50%;
  }
}
@keyframes haha-mouth {
  10%,
  30%,
  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    top: 45%;
  }
  20%,
  40% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    top: 45%;
  }
  60%,
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    top: 50%;
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    top: 50%;
  }
  90% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    top: 50%;
  }
}
@-webkit-keyframes yay {
  25% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  75% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}
@keyframes yay {
  25% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  75% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}
@-webkit-keyframes wow-face {
  15%,
  25% {
    -webkit-transform: rotate(20deg) translateX(-10px);
    transform: rotate(20deg) translateX(-10px);
  }
  45%,
  65% {
    -webkit-transform: rotate(-20deg) translateX(10px);
    transform: rotate(-20deg) translateX(10px);
  }
  75%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
}
@keyframes wow-face {
  15%,
  25% {
    -webkit-transform: rotate(20deg) translateX(-10px);
    transform: rotate(20deg) translateX(-10px);
  }
  45%,
  65% {
    -webkit-transform: rotate(-20deg) translateX(10px);
    transform: rotate(-20deg) translateX(10px);
  }
  75%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
}
@-webkit-keyframes wow-brow {
  15%,
  65% {
    top: 10px;
  }
  75%,
  100%,
  0% {
    top: 6px;
  }
}
@keyframes wow-brow {
  15%,
  65% {
    top: 10px;
  }
  75%,
  100%,
  0% {
    top: 6px;
  }
}
@-webkit-keyframes wow-mouth {
  10%,
  30% {
    width: 8px;
    height: 8px;
    left: calc(50% - 4px);
  }
  50%,
  70% {
    width: 12px;
    height: 16px;
    left: calc(50% - 6px);
  }
  75%,
  100% {
    height: 20px;
  }
}
@keyframes wow-mouth {
  10%,
  30% {
    width: 8px;
    height: 8px;
    left: calc(50% - 4px);
  }
  50%,
  70% {
    width: 12px;
    height: 16px;
    left: calc(50% - 6px);
  }
  75%,
  100% {
    height: 20px;
  }
}
@-webkit-keyframes sad-face {
  25%,
  35% {
    top: -6px;
  }
  55%,
  95% {
    top: 4px;
  }
  100%,
  0% {
    top: 0;
  }
}
@keyframes sad-face {
  25%,
  35% {
    top: -6px;
  }
  55%,
  95% {
    top: 4px;
  }
  100%,
  0% {
    top: 0;
  }
}
@-webkit-keyframes sad-mouth {
  25%,
  35% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    top: 28px;
  }
  55%,
  100%,
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    top: 32px;
  }
}
@keyframes sad-mouth {
  25%,
  35% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    top: 28px;
  }
  55%,
  100%,
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    top: 32px;
  }
}
@-webkit-keyframes tear-drop {
  0%,
  100% {
    display: block;
    left: 14px;
    top: 6px;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  25% {
    display: block;
    left: 14px;
    -webkit-transform: rotate(45deg) scale(2);
    transform: rotate(45deg) scale(2);
  }
  49.9% {
    display: block;
    left: 14px;
    top: 26px;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    display: block;
    left: -14px;
    top: 6px;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  75% {
    display: block;
    left: -14px;
    -webkit-transform: rotate(45deg) scale(2);
    transform: rotate(45deg) scale(2);
  }
  99.9% {
    display: block;
    left: -14px;
    top: 26px;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
}
@keyframes tear-drop {
  0%,
  100% {
    display: block;
    left: 14px;
    top: 6px;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  25% {
    display: block;
    left: 14px;
    -webkit-transform: rotate(45deg) scale(2);
    transform: rotate(45deg) scale(2);
  }
  49.9% {
    display: block;
    left: 14px;
    top: 26px;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    display: block;
    left: -14px;
    top: 6px;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  75% {
    display: block;
    left: -14px;
    -webkit-transform: rotate(45deg) scale(2);
    transform: rotate(45deg) scale(2);
  }
  99.9% {
    display: block;
    left: -14px;
    top: 26px;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
}
@-webkit-keyframes hands-up {
  25% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  50% {
    -webkit-transform: rotate(-15deg) translateY(-4px);
    transform: rotate(-15deg) translateY(-4px);
  }
  75%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes hands-up {
  25% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  50% {
    -webkit-transform: rotate(-15deg) translateY(-4px);
    transform: rotate(-15deg) translateY(-4px);
  }
  75%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes thumbs-up {
  25% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  50%,
  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}
@keyframes thumbs-up {
  25% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  50%,
  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}
@-webkit-keyframes angry-color {
  45%,
  60% {
    background-size: 250%;
  }
  85%,
  100%,
  0% {
    background-size: 100%;
  }
}
@keyframes angry-color {
  45%,
  60% {
    background-size: 250%;
  }
  85%,
  100%,
  0% {
    background-size: 100%;
  }
}
@-webkit-keyframes angry-face {
  35%,
  60% {
    -webkit-transform: translateX(0) translateY(4px) scale(0.9);
    transform: translateX(0) translateY(4px) scale(0.9);
  }
  40%,
  50% {
    -webkit-transform: translateX(-2px) translateY(4px) scale(0.9);
    transform: translateX(-2px) translateY(4px) scale(0.9);
  }
  45%,
  55% {
    -webkit-transform: translateX(2px) translateY(4px) scale(0.9);
    transform: translateX(2px) translateY(4px) scale(0.9);
  }
}
@keyframes angry-face {
  35%,
  60% {
    -webkit-transform: translateX(0) translateY(4px) scale(0.9);
    transform: translateX(0) translateY(4px) scale(0.9);
  }
  40%,
  50% {
    -webkit-transform: translateX(-2px) translateY(4px) scale(0.9);
    transform: translateX(-2px) translateY(4px) scale(0.9);
  }
  45%,
  55% {
    -webkit-transform: translateX(2px) translateY(4px) scale(0.9);
    transform: translateX(2px) translateY(4px) scale(0.9);
  }
}
@-webkit-keyframes angry-mouth {
  25%,
  50% {
    height: 2.4px;
    bottom: 10px;
  }
}
@keyframes angry-mouth {
  25%,
  50% {
    height: 2.4px;
    bottom: 10px;
  }
}
